.img-container-dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  img {
    width: 180px;
    padding: 20px 10px 8px 7px;
  }
}

.img-container-download {
  display: flex;
  justify-content: space-around;
}

.img-download {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  img {
    width: 200px;
    padding: 20px;
  }
}

.container-grid {
  padding: 1rem;
}

.container-grid-right {
  text-align: center;
}

.title-dashboard {
  text-align: center;
}

.number-vacancies {
  font-size: 42px;
  color: blue;
  font-weight: 500;
}

.nav-link-vacancies {
  text-decoration: none;
}
