$primary: #274490;
$secondary: #6f85bd;
$success: #2e7d32;

$dark-blue: $primary;
$sub-font-weight: 100;
$light-blue: #5088e1;
$dark-green: #30a64a;
$sub-font-color: rgba(41, 50, 65, 0.5);
$main-title-font-size: 22px;
$background-color: rgba(244, 243, 243, 0.06);
$color-text: rgba(41, 50, 65, 0.8);
