.exception-body .exception-topbar {
  height: 62px;
  background-color: #ffffff;
  box-shadow: 0 10px 40px 0 rgba(41, 50, 65, 0.06);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
}
.exception-body .exception-topbar .layout-topbar-logo > img {
  height: 48px;
}
.exception-body .exception-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100vh - 8px);
}
.exception-body .exception-wrapper .exception-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  flex: 1 1 auto;
}
.exception-body .exception-wrapper .exception-content > span {
  font-weight: normal;
  font-size: 60px;
  line-height: 73px;
  text-align: center;
  display: block;
  color: black;
  margin-bottom: 24px;
}
.exception-body .exception-wrapper .exception-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 70px;
}
.exception-body .exception-wrapper .exception-footer h4 {
  line-height: 22px;
  margin: 0;
  margin-right: 32px;
}
.exception-body .exception-wrapper .exception-footer h6 {
  line-height: 17px;
  margin: 0;
  color: rgba(41, 50, 65, 0.5);
  font-weight: 500;
}
.exception-body.notfound .exception-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100vh - 62px);
}
.exception-body.notfound .exception-wrapper .exception-content {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  flex: 1 1 auto;
}
.exception-body.notfound .exception-wrapper .exception-content img {
  width: 332px;
  height: 271px;
  margin-bottom: -150px;
}
.exception-body.notfound .exception-wrapper .exception-content > span {
  font-size: 140px;
  line-height: 171px;
}
.exception-body.notfound
  .exception-wrapper
  .exception-content
  > span.exception-subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(41, 50, 65, 0.5);
}
.exception-body.notfound .exception-wrapper .exception-content > button {
  padding: 0;
  margin-top: 20px;
  width: 155px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
}
.exception-body.notfound .exception-wrapper .exception-content > button > span {
  padding: 18px;
  font-weight: 600;
}

@media (max-width: 991px) {
  .exception-body .exception-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100vh - 8px);
  }
  .exception-body .exception-wrapper .exception-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 20px;
    color: black;
  }
}
