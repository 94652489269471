@use '../../../../data/components/globalStyles';

$light-blue: globalStyles.$light-blue;
$main-title-font-size: 22px;
$sub-title-font-size: 20px;
$card-font-size: 14px;

.register-new-container {
  padding: 2rem 4rem 4rem 4rem;

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .content-box-data {
    border-radius: 24px;
    background-color: white;
    padding: 2rem;
    color: globalStyles.$color-text;
    margin: 2rem;
  }
  .horinzontal {
    display: flex;
    flex-direction: column;
    min-width: 68%;
    padding-right: 2%;
    color: #000000;
  }
  .img_profile {
    width: 150px;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
    object-fit: cover;
    margin-top: 10px;
    border-radius: 50% !important;
  }
  .normal {
    border: 4px solid #2b4790;
  }
  .error {
    border: 4px solid red;
  }
  .camera-icon {
    border: none;
    width: 40px;
    bottom: 0;
    vertical-align: bottom;
    margin-left: -3rem;
  }
  .box-all {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .align {
    min-width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .foto {
    cursor: pointer;
  }
  .no-hidden {
    display: none;
  }
  .helper-box {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    color: red;
    min-height: 20px;
    display: flex;
  }
  .button-container {
    margin-left: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .container-raio {
    margin-top: 3rem;
  }
  .container-slider {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .accordion-summary {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    .title {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #4a4a4a;
    }
  }
  .button-submit {
    margin: 0 2rem 0;
    min-width: 200px;
  }
  .termosUso {
    cursor: pointer;
    color: #1976d2;
    text-decoration: underline;
    &:hover {
      color: #1565c0;
    }
  }
  .input-label {
    font-weight: 300;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.57142857;
  }
}
