.img-container-dashboard {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;

  img {
    display: flex;
    width: 150px;
    padding: 20px 10px 8px 7px;
    justify-content: center;
  }
}

.promotion-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 370px;
  border-radius: 10px;
  margin: 0px 12px;

  .promotion-title {
    font-weight: 450;
    font-size: medium;
  }

  .promotion-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-align: justify;
  }

  .promotion-button {
    width: 100%;
  }
}

.img-container-download {
  display: flex;
  justify-content: space-around;
}

.img-download {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  img {
    width: 200px;
    padding: 20px;
  }
}

.container-grid {
  padding: 1rem;
}

.container-grid-right {
  text-align: center;
}

.title-dashboard {
  text-align: center;
}

.number-vacancies {
  font-size: 42px;
  color: blue;
  font-weight: 500;
}

.nav-link-vacancies {
  text-decoration: none;
}
