.header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header__title {
  color: #1876d1;
  font-size: 1.2rem;
}
