.container {
  gap: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey;
  padding: 20px 50px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 5px #d0ddea;
  background: white;
}
