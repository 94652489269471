.material-ui-modal {
  top: 50%;
  left: 50%;
  width: 400;
  padding: 40px;
  box-shadow: 24;
  position: absolute;
  background-color: white;
  transform: translate(-50%, -50%);
}

.material-ui-modal-footer {
  border-top: 1px solid lightgrey;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  padding: 20px;
}
