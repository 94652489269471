@import '../../../assets/scss/variables.scss';

.service-request-modal-container {
  padding: 0px;
  color: black;

  .modal-confirm-button {
    background-color: $primary;
  }
}

.service-request {
  display: flex;
  flex-direction: column;
  width: 900px;
  height: 300px;
}

.service-request-header {
  color: white;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  h1{
    font-size:20px;
  }
}

.service-request-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.service-request-message {
  font-size: 20px;
}

.bold-line {
  font-weight: bold;
}
