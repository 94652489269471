.onboarding-controller-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.onboarding-controller-user-container {
  gap: 10px;
}

.onboarding-controller-user-filter {
  width: 100%;
}

.users-list {
  color: black;
}
