@import '../../../data/components/globalStyles';

.accounting-menu {
  @extend .flex-box;
  color:#666666;
  gap: 50px;
}

.accounting-menu-footer{
  color:#666666;
  font-size: 19px;
}

.accounting-option-title {
  padding: 40px;
  border-top: 1px solid lightgrey;
}

.onboarding-option-icon {
  opacity: 70%;
  margin: 70px;
  color: $light-blue;
  font-size: 90px !important;
}

.accounting-option {
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
  align-items: center;
  box-shadow: 2px 2px 10px 5px #d0ddea;
}

.accounting-option:hover {
  transform: scale(0.9);
}

.onboarding-option {
  @extend .accounting-option;
}

.blocked-accounting-option {
  opacity: 40%;
  cursor: not-allowed;
}

.fiscal-note-option {
  @extend .accounting-option;
}

.bankslip-option {
  @extend .accounting-option;
}
