$dark-blue: #264391;
$primary: #264391;
$sub-font-weight: 100;
$light-blue: #5088e1;
$dark-green: #30a64a;
$main-title-font-size: 22px;
$color-text: rgba(41, 50, 65, 0.8);
$sub-font-color: rgba(41, 50, 65, 0.5);
$background-color: rgba(244, 243, 243, 0.06);

.app-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.flex-box {
  display: flex;
  flex-wrap: wrap;
}

.flex-box-center {
  @extend .flex-box;
  align-items: center;
  justify-content: center;
}

.light-blue-button {
  color: white !important;
  background-color: $light-blue !important;
}

.light-grey-button {
  color: grey !important;
}

.box-container {
  gap: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 40px 40px 40px;
  box-shadow: 2px 2px 10px 5px #d0ddea;

  &-header {
    gap: 20px;
    height: 70px;
    display: flex;
    color: $light-blue;
    align-items: center;
    font-size: $main-title-font-size;
    border-bottom: 1px solid lightgrey;

    .MuiSvgIcon-root {
      font-size: 30px;
    }
  }
}
