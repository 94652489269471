@import './../assets/scss/variables.scss';
.signup-professional {
  min-height: 100vh;
  background: $primary;
  background-image: linear-gradient(to bottom, #6f85bd 0, $primary 100%);
  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: $primary;
  }
  .css-8bjq0v-MuiButtonBase-root-MuiButton-root {
    background-color: $primary;
  }
  .css-8bjq0v-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    background-color: #ccc;
    color: #888 !important;
  }
  .MuiButton-textPrimary,
  .Mui-active {
    color: $primary;
  }
  .Mui-completed {
    color: $secondary;
  }
}
