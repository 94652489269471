@import '../../assets/scss/variables.scss';
.App {
  height: 100vh;
}
.login-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: $primary;

  .login {
    width: 600px;
    height: 400px;
    display: flex;
    padding: 100px 50px;
    align-items: center;
    border-radius: 15px;
    background-color: white;
    justify-content: space-between;

    img {
      width: 260px;
      height: auto;
    }

    .form-container {
      gap: 10px;
      height: 100%;
      width: 220px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      input {
        height: 30px;
      }

      button {
        margin-top: 1rem;
        width: 100%;
      }
      .btn-submit {
        background-color: $primary;
        color: #fff;
        &:hover {
          background-color: darken($secondary, 30%);
        }
      }
    }
  }
}
