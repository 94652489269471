@use '../../data/components/globalStyles.scss';

.horinzontal {
  display: flex;
  flex-direction: column;
  min-width: 68%;
  padding-right: 2%;
}
.box-all {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.align {
  min-width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.foto {
  cursor: pointer;
}
.img_profile {
  width: 150px;
  height: 150px;
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
  margin-top: 10px;
  border-radius: 50% !important;
}
.normal {
  border: 4px solid #2b4790;
}
.error {
  border: 4px solid red;
}
.camera-icon {
  border: none;
  width: 40px;
  bottom: 0;
  vertical-align: bottom;
  margin-left: -3rem;
}
.button-submit {
  margin: 1rem 0 0 !important;
  min-width: 200px !important;
}
.input-label {
  font-weight: 300;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.57142857;
  cursor: pointer;
}
.container-raio {
  margin-top: 3rem;
}
.container-slider {
  padding-left: 1rem;
  padding-right: 1rem;
}
.accordion-summary {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  .title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #4a4a4a;
  }
}
