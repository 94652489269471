.partner-data-container {
  gap: 40px;
  width: 100%;
  display: flex;
  margin-bottom: 60px;
}

.partner-form-container {
  gap: 20px;
  width: 70%;
  display: flex;
  flex-direction: column;

  .add-partner-button {
    width: 30%;
  }
  .dependent-form-list {
    display: flex;
    gap: 20px;

    .dependents-simple-list {
      width: 40%;

      &-body {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .dependent-line {
          display: flex;
          width: 100%;
          gap: 10px;
          flex-wrap: wrap;
          justify-content: space-between;
          color: black;
          .MuiSvgIcon-root {
            cursor: pointer;
          }
        }
      }
    }
  }
}
