.contract__container {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.contract {
  padding: 0.5rem;
  width: 250px;
  text-align: center;
  border-radius: 4px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 60px;
    height: 60px;
  }

  &__name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-height: 1.2;
    max-height: 2.4em;
  }

  &__remove {
    position: absolute;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 4px;
  }

  &:hover &__remove {
    transition: 1s display ease-in;
    background: rgba(250, 250, 250, 0.9);
    display: flex;
    border: 1px solid rgb(255, 75, 82);
  }
}
