.we-found-your-job-container {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .preferencies-container {
    margin-bottom: 24px;
  }

  .vacancies-container {
    display: grid;
    gap: 12px;

    .vacancy-card {
      line-height: 1.6;

      .card-action-area {
        padding: 8px;
      }

      .company-card {
        display: flex;
        border-radius: 12px;
      }

      .company-logo {
        width: 200px;
        padding: 16px 12px 8px 8px;
      }

      .vacancy-place {
        font-weight: 400;
        font-size: 1rem;
        display: block;
        list-style: none;
      }

      .title {
        font-weight: 500;
        font-size: 1.25rem;
      }
    }
  }
}
