.box-container-header {
  p {
    font-size: 20px;
  }
}

.tabs-header {
  text-align: center;
}

.profileContainer {
  color: black;
  .tab-panel-container {
    padding: 20px 0px 20px 20px;
  }
  .profile-tab {
    font-size: 15px;
  }

  .profile-tab:hover {
    transform: scale(0.9);
  }
}

/* Popup container - can be anything you want */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: 250px;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: 90px;
  display: flex;
  flex-direction: column;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.onboarding-progress {
  gap: 20px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: #666666;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
}

.panels {
  padding: 1.5rem 0.75rem;
}
