@import '../../../assets/scss/variables';

.responsibility-start-container {
  gap: 30px;
  display: flex;
}
.responsibility-start-box {
  gap: 10px !important;
}

.responsibility-content-title{
  font-size:19px;
}

.responsibility-start-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dcdcdc;

  .MuiFormControl-root {
    width: 200px;
  }
}

.important-things {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dcdcdc;
}
.responsibility-content-footer {
  color: $color-text;
  font-size:17px;
}

.responsabilities-subtitle {
  font-weight: bold;
}

.activitation-text {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dcdcdc;
}
