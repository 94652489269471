@use '../../../data/components/globalStyles';
@import '../../../assets/scss/variables.scss';

$light-blue: globalStyles.$light-blue;
$main-title-font-size: 22px;
$sub-title-font-size: 20px;
$card-font-size: 14px;

.register-new-user-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $background-color;
  height: -webkit-fill-available;
  height: -moz-available;

  img {
    margin-bottom: 1rem;
  }

  .content-box {
    gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
    padding-top: 60px;
  }
}
