@use '../../../data/components/globalStyles';

$light-blue: globalStyles.$light-blue;
$main-title-font-size: 22px;
$sub-title-font-size: 20px;
$card-font-size: 14px;

.partner-form {
  width: 100%;

  &-body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .MuiFormControl-root {
      width: 30%;
    }

    .name-cpf-quota {
      display: flex;
      gap: 20px;

      .quota {
        width: 10%;
      }
    }

    .prolabore-value-pis-nit {
      display: flex;
      gap: 20px;
    }

    button {
      width: 20%;
    }
  }
}
