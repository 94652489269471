@import '../../../data/components/globalStyles';

.requested-service-form {
  width: 50%;
}

.requested-service-form-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.requested-service-name-email {
  display: flex;
  gap: 20px;
}

.requested-service-phone {
  display: flex;
  gap: 20px;
}

.requested-done-paid-note-issued {
  display: flex;
  gap: 20px;
  color: black;
}

// button {
//   width: 20%;
// }
