.we-found-your-job-detail {
  padding-bottom: 48px;

  .job-description {
    display: grid;
    gap: 16px;

    margin-bottom: 24px;

    .company-logo {
      width: 200px;
    }

    .job-attribute {
      .name {
        display: block;
        font-weight: 500;
        font-size: 1.1rem;
        justify-content: center;
      }
    }
  }
}
