.admin-area-container {
  .tab-panel-container {
    padding: 20px 0px 20px 20px;
  }
}

.admin-area-tab {
  font-size: 15px;
}

.admin-area-tab:hover {
  transform: scale(0.9);
}
