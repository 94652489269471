@use '../../../data/components/globalStyles';

$light-blue: globalStyles.$light-blue;
$main-title-font-size: 22px;
$sub-title-font-size: 20px;
$card-font-size: 14px;
$dark-green: globalStyles.$dark-green;

input[type='file'] {
  display: none;
}

.form__company {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form__submit {
  width: max-content;
  align-self: flex-start;
}
