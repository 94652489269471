@use "data/components/globalStyles";

$light-blue: globalStyles.$light-blue;
$main-title-font-size: 22px;
$sub-title-font-size: 20px;
$card-font-size: 14px;

.bankslip-container {
  gap: 20px;
  display: flex;
  padding-bottom: 60px;
  flex-direction: column;

  .Mui-active,
  .Mui-completed {
    color: $light-blue !important;
  }

  .send-bankslip {
    width: 35%;
    align-self: center;
    padding: 10px 70px 50px;
    border: 1px solid lightgrey;
    box-shadow: 2px 2px 10px 5px #d0ddea;

    &-title {
      color: $light-blue;
      padding: 0 0 15px 0;
      border-bottom: 1px solid lightgrey;
      font-size: $main-title-font-size;
    }

    .send-bankslip-form {
      gap: 30px;
      display: flex;
      flex-direction: column;
    }

    .description-container {
      width: 100%;
      min-width: 200px;

      p {
        color: rgba(0, 0, 0, 0.54);
      }
      textarea {
        width: 100%;
        padding: 10px;
        border-radius: 7px;
      }
    }

    .value-datepicker-container {
      gap: 20px;
      display: flex;

      .MuiFormControl-root {
        width: 10%;
        height: 100%;
        min-width: 200px;
      }

      .MuiOutlinedInput-input {
        padding: 18.5px 16px;
      }
    }
  }

  .bankslip-info {
    width: 35%;
    align-self: center;
    padding: 10px 70px 50px;
    border: 1px solid lightgrey;
    box-shadow: 2px 2px 10px 5px #d0ddea;

    &-title {
      color: $light-blue;
      padding: 0 0 15px 0;
      font-size: $main-title-font-size;
      border-bottom: 1px solid lightgrey;
    }

    .title {
      color: $light-blue;
      font-size: 16px !important;
    }

    .bankslip-general-info {
      display: flex;
      margin: 20px 0;
      padding-bottom: 20px;
      border-bottom: 1px solid lightgrey;
    }

    .client-data {
      display: flex;
      flex-direction: column;
      width: 50%;

      p {
        font-size: 13px;
        margin: 3px;
      }
    }

    .bankslip-account-data {
      display: flex;
      flex-direction: column;
      width: 50%;

      p {
        font-size: 13px;
        margin: 3px;
      }
    }

    .bankslip-info-data {
      width: 100%;

      .value-date {
        display: flex;
        gap: 20px;

        .MuiOutlinedInput-input {
          padding: 18.5px 16px;
        }
      }

      .description {
        padding-bottom: 20px;

        p {
          color: $light-blue;
        }
        textarea {
          width: 100%;
        }
      }
    }
  }

  .bankslip-footer {
    display: flex;
    gap: 30px;
    width: 100%;
  }
}
