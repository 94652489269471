@use "../../../data/components/globalStyles";

$light-blue: globalStyles.$light-blue;
$main-title-font-size: 22px;
$sub-title-font-size: 20px;
$card-font-size: 14px;

.dependent-form {
  width: 100%;

  &-body {
    display: flex;
    flex-direction: column;
    gap: 50px;

    .MuiFormControl-root {
      width: 40%;
    }

    .name-cpf {
      display: flex;
      gap: 20px;
    }

    .birthdate-relationship {
      display: flex;
      height: 55px;
      gap: 20px;
    }

    button {
      width: 30%;
    }
  }
}
