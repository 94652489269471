.dependent-data-container {
  width: 100%;
  display: flex;
  margin-bottom: 60px;
}

.dependent-data-toolbar {
  margin-right: 40px;
  transform: translateZ(0px);
}

.add-dependent-form {
  width: 40%;
}
