@use "data/components/globalStyles";

$light-blue: globalStyles.$light-blue;
$main-title-font-size: 22px;
$sub-title-font-size: 20px;
$card-font-size: 14px;

.billing-history-container {
  gap: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;

  .billing-history {
    padding: 10px 70px 50px;
    border: 1px solid lightgrey;
    box-shadow: 2px 2px 10px 5px #d0ddea;

    &-title {
      color: $light-blue;
      padding: 0 0 15px 0;
      font-size: $sub-title-font-size;
    }
  }
}
