.my-responsibility-container {
  gap: 30px;
  display: flex;
  color: black;
}

.activitation-text {
  margin-bottom: 20px;
}

.my-responsibilities-content{
  display: flex;
  flex-direction:column;
  gap: 15px;
}