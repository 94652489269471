@use "data/components/globalStyles";

$light-blue: globalStyles.$light-blue;
$main-title-font-size: 22px;
$sub-title-font-size: 20px;
$card-font-size: 14px;

.client-info {
  width: 40%;
  align-self: center;
  padding: 10px 70px 50px;
  border: 1px solid lightgrey;
  box-shadow: 2px 2px 10px 5px #d0ddea;

  &-title {
    color: $light-blue;
    padding: 0 0 15px 0;
    border-bottom: 1px solid lightgrey;
    font-size: $main-title-font-size;
  }

  .client-info-form {
    display: flex;
    flex-direction: column;

    .MuiFormControl-root {
      width: 45%;
    }

    p {
      color: $light-blue;
    }

    &-fields {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .cnpj-fantasy-name {
      display: flex;
      gap: 40px;
    }

    .phone-email {
      display: flex;
      gap: 40px;
    }
  }

  .address-info-form {
    display: flex;
    flex-direction: column;
    margin: 50px 0;

    p {
      color: $light-blue;
    }

    &-fields {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .MuiFormControl-root {
      width: 45%;
    }

    .public-place-number,
    .complement-district,
    .state-city {
      gap: 40px;
      display: flex;
    }
  }
}
