@import '../../../assets/scss/variables.scss';

.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  img {
    width: 70px;
    padding: 12px 10px 10px 10px;
  }
}

.sidebar-list-container {
  .MuiPaper-root {
    background-color: $dark-blue !important;
    color: white;
  }

  .MuiListItemIcon-root {
    color: white;
  }

  .MuiPaper-root::-webkit-scrollbar {
    width: 0.5rem;
  }

  .MuiPaper-root::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .MuiPaper-root::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

.list-item {
  flex-direction: column;

  .MuiListItemButton-root {
    height: 48px;
    width: 100% !important;
    padding-left: 15px !important;
    border: 5px solid $dark-blue;

    .MuiListItemIcon-root {
      min-width: 20px;
      margin-right: 22px;
    }

    .MuiTypography-root {
      font-size: 14px;
    }
  }

  .MuiListItemButton-root:hover {
    transform: scale(1.02);
  }

  .selected-sub-itens {
    .MuiTypography-root {
      font-size: 10px;
    }
    .MuiSvgIcon-root {
      width: 19px;
      height: 19px;
    }
  }
}
