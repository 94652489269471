.service-contract-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.complete-contract-header {
  gap: 80px !important;
  display: flex;
  align-items: center;
}

.simplified-service-contract-container {
  gap: 20px;
  display: flex;
  align-items: center;
}

.complete-contract {
  max-height: 400px;
  width: 100%;
  overflow-y: scroll;
}

.complete-contract-footer {
  margin-top: 20px;
  color: black;
}

.complete-service-contract-container {
  gap: 50px;
  display: flex;
  flex-direction: column;
}
