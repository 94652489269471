body,
.content-container {
  background: #f1f4f5;
}

body::-webkit-scrollbar {
  width: 0.6rem;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.layout-content {
  padding: 30px 20px;
  flex: 1 1 auto;
}
